<template>
  <b-container>
    <b-navbar toggleable="lg" class="m-0 p-0">
      <b-navbar-brand href="#">
        <div class="site-logo">
          <router-link to="/">
            <img :src="require('../../assets/img/logo.png')" alt="site logo" />
          </router-link>
        </div>
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <template v-for="(item, key) in menus">
            <b-nav-item v-if="!item.submenus" :key="key" :active="!!item.isActive" :to="{name: item.name, path: item.path}">{{item.title}}</b-nav-item>
            <b-nav-item-dropdown :text="item.title" :active="!!item.isActive" :key="key" right v-else>
              <template v-for="(subItem, i) in item.submenus">
                <b-dropdown-item :to="{name: subItem.name, path: subItem.path}" :key="i">{{subItem.title}}</b-dropdown-item>
              </template>
            </b-nav-item-dropdown>
          </template>
        </b-navbar-nav>
        <b-navbar-nav class="ml-5">
          <b-nav-item>
            <div class="search-box"><i class="fa fa-search" @click="searchHandler()"></i></div>
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <search-overlay :is-search-active="isSearchActive" @searchHandler="searchHandler"/>
  </b-container>
</template>

<script>
import SearchOverlay from "../SearchOverlay";
export default {
  name: "MainMenu",
  components: {SearchOverlay},
  data() {
    return {
      menus: [
        {
          title: 'Home',
          name: 'Home',
          path: '/'
        },
        {
          title: 'About',
          name: 'About',
          path: '/about'
        },
        {
          title: 'services',
          submenus: [
            {
              title: 'All Services',
              name: 'AllServices',
              path: '/all-services'
            },
            {
              title: 'Service Details',
              name: 'ServiceDetails',
              path: '/service-details'
            }
          ]
        },
        {
          title: 'project',
          submenus: [
            {
              title: 'All Projects',
              name: 'AllProjects',
              path: '/all-projects'
            },
            {
              title: 'Project Details',
              name: 'ProjectDetails',
              path: '/project-details'
            }
          ]
        },
        {
          title: 'pages',
          submenus: [
            {
              title: 'Our Team',
              name: 'OurTeam',
              path: '/page/our-team'
            },
            {
              title: 'FAQ',
              name: 'FAQ',
              path: '/page/faq'
            },
            {
              title: 'Error Page',
              name: 'ErrorPage',
              path: '/page/error-page'
            },
            {
              title: 'Login',
              name: 'Login',
              path: '/page/login'
            },
            {
              title: 'Register',
              name: 'Register',
              path: '/page/register'
            }
          ]
        },
        {
          title: 'blog',
          submenus: [
            {
              title: 'Blog',
              name: 'Blog',
              path: '/blog'
            },
            {
              title: 'Single Blog',
              name: 'SingleBlog',
              path: '/single-blog'
            }
          ]
        },
        {
          title: 'Contact',
          name: 'Contact',
          path: '/contact'
        },
      ],
      isSearchActive: 'none'
    }
  },
  mounted() {
     this.menuHandler();
  },
  methods: {
    menuHandler() {
      const routeName = this.$route.name;

      this.menus = this.menus.map((item) => {
        if(item.name === routeName) {
          item.isActive = true;
        }

        return {
          ...item
        }
      })
    },
    searchHandler(value) {
      if(value === 'stop') {
        this.isSearchActive = 'none';
        return;
      }

      this.isSearchActive = 'block';
    }
  }
}
</script>

<style scoped>

</style>