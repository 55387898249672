<template>
  <!-- Promo Area Start -->
  <section class="ekifa-promo-area section_t_80 section_b_50">
    <b-container>
      <b-row>
        <b-col md="4" v-for="(item, key) in promoData" :key="key">
          <div class="single-promo">
            <div class="promo-icon">
              <i :class="`fa fa-${item.icon}`"></i>
            </div>
            <div class="promo-text">
              <h3>{{item.title}}</h3>
              <p>{{item.description}}</p>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
  <!-- Promo Area End -->
</template>

<script>
export default {
  name: "PromoArea",
  data() {
    return {
      promoData: [
        {
          icon: 'bullhorn',
          title: 'Boost Your Business',
          description: 'Lorem ipsum dolor sit amet, vis quis cibo eu. That dolorem deleniti nominati ad. Dolore nonumy to libris te est,'
        },
        {
          icon: 'bar-chart',
          title: 'Branding Service',
          description: 'Lorem ipsum dolor sit amet, vis quis cibo eu. That dolorem deleniti nominati ad. Dolore nonumy to libris te est,'
        },
        {
          icon: 'bell-slash',
          title: '24/7 Quick Support',
          description: 'Lorem ipsum dolor sit amet, vis quis cibo eu. That dolorem deleniti nominati ad. Dolore nonumy to libris te est,'
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>