import Vue from 'vue';
import VueRouter from 'vue-router';
import AppHeader from "../layouts/AppHeader";
import AppFooter from "../layouts/AppFooter";
import Home from '../views/Home.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    components: {
      header: AppHeader,
      default: Home,
      footer: AppFooter
    }

  },
  {
    path: '/about',
    name: 'About',
    components: {
      header: AppHeader,
      default: () => import('../views/About.vue'),
      footer: AppFooter
    }
  },
  {
    path: '/all-services',
    name: 'AllServices',
    components: {
      header: AppHeader,
      default: () => import('../views/services/AllServices'),
      footer: AppFooter
    }
  },
  {
    path: '/service-details',
    name: 'ServiceDetails',
    components: {
      header: AppHeader,
      default: () => import('../views/services/ServiceDetails'),
      footer: AppFooter
    }
  },
  {
    path: '/all-projects',
    name: 'AllProjects',
    components: {
      header: AppHeader,
      default: () => import('../views/projects/AllProjects'),
      footer: AppFooter
    }
  },
  {
    path: '/project-details',
    name: 'ProjectDetails',
    components: {
      header: AppHeader,
      default: () => import('../views/projects/ProjectDetails'),
      footer: AppFooter
    }
  },
  {
    path: '/page/our-team',
    name: 'OurTeam',
    components: {
      header: AppHeader,
      default: () => import('../views/page/OurTeam'),
      footer: AppFooter
    }
  },
  {
    path: '/page/faq',
    name: 'FAQ',
    components: {
      header: AppHeader,
      default: () => import('../views/page/Faq'),
      footer: AppFooter
    }
  },
  {
    path: '/page/error-page',
    name: 'ErrorPage',
    components: {
      header: AppHeader,
      default: () => import('../views/page/ErrorPage'),
      footer: AppFooter
    }
  },
  {
    path: '/page/login',
    name: 'Login',
    components: {
      header: AppHeader,
      default: () => import('../views/page/Login'),
      footer: AppFooter
    }
  },
  {
    path: '/page/register',
    name: 'Register',
    components: {
      header: AppHeader,
      default: () => import('../views/page/Register'),
      footer: AppFooter
    }
  },
  {
    path: '/blog',
    name: 'Blog',
    components: {
      header: AppHeader,
      default: () => import('../views/blog/Blog'),
      footer: AppFooter
    }
  },
  {
    path: '/single-blog',
    name: 'SingleBlog',
    components: {
      header: AppHeader,
      default: () => import('../views/blog/SingleBlog'),
      footer: AppFooter
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    components: {
      header: AppHeader,
      default: () => import('../views/Contact'),
      footer: AppFooter
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
