/*=========================================================================================
File Name: App.vue
Description: Main Vue File - APP.
----------------------------------------------------------------------------------------
Item Name: Ekifa Vue || Business and Agency VueJs Template
Version: 1.0.0
Author: Deveable
Copyright 2021
==========================================================================================*/

<template>
  <div id="app">
    <!--    Preloader-->
    <preloader :is-loading="isLoading"/>
    <!--    Header Section-->
    <router-view name="header" />
    <!--    Body Section-->
    <router-view />
    <!--    Footer Section-->
    <router-view name="footer" />
  </div>
</template>

<script>
import Preloader from "./components/Preloader";
export default {
  name: 'App',
  components: {Preloader},
  metaInfo: {
    title: 'Business and Agency VueJs Template',
    titleTemplate: 'Ekifa Vue || %s',
    meta: [
      { name: 'robots', content: 'noindex' },
      { name: 'googlebot', content: 'noindex' }
    ]
  },
  data() {
    return {
      isLoading: false,
    }
  },
  mounted() {
    this.preloaderHandler();
  },
  methods: {
    preloaderHandler() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false
      }, 2000)
    }
  }
}
</script>

<style scoped>

</style>
