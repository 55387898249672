<template>
    <!-- Header Area Start -->
    <header class="ekifa-header-area">
      <!-- Header Nav Menu -->
      <nav-menu/>
      <div class="header-mainmenu">
        <!-- Header Main Menu -->
        <main-menu/>
      </div>
    </header>
    <!-- Header Area End -->
</template>

<script>
import NavMenu from "../components/menus/NavMenu";
import MainMenu from "../components/menus/MainMenu";
export default {
  name: "AppHeader",
  components: {MainMenu, NavMenu}
}
</script>

<style scoped>

</style>