<template>
  <!-- Project Area Start -->
  <section class="ekifa-project-area section_80">
    <b-container>
      <b-row>
        <b-col>
          <div class="site-heading">
            <h2>Our Portfolio</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed quis accumsan nisi Ut ut felis congue nisl hendrerit commodo. </p>
          </div>
        </b-col>
      </b-row>
      <div class="portfolio-warp">
        <ul class="portfolio-filter">
          <li :class="`filter ${item.isActive ? 'active' : ''}`" v-for="(item, key) in categories" :key="key" @click="projectHandler(item.type)">{{item.name}}</li>
        </ul>
        <b-row class="isotope_items">
          <b-col lg="4" md="6" v-for="(item, key) in projects" :key="key">
            <div class="work-item work-image">
              <div class="project-img">
                <img :src="require(`../assets/img/${item.img}`)" alt="project">
              </div>
              <div class="content">
                <p>{{item.name}}</p>
                <h4 class="name">{{item.title}}</h4>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-row>
        <b-col>
          <div class="load-btn">
            <b-link class="ekifa-btn">load more projects</b-link>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
  <!-- Project Area End -->
</template>

<script>
const projects = require('../json/projects.json')
export default {
  name: "ProjectArea",
  data() {
    return {
      categories: [
        {
          name: 'All Works',
          type: 'all',
          isActive: true
        },
        {
          name: 'Branding',
          type: 'brand',
          isActive: false
        },
        {
          name: 'Design',
          type: 'design',
          isActive: false
        },
        {
          name: 'Photography',
          type: 'photo',
          isActive: false
        }
      ],
      projects: projects
    }
  },
  methods: {
    projectHandler(type) {
      this.categories = this.categories.map((cat) => {
        return {
          ...cat,
          isActive:  type === cat.type
        }
      });
      if(type === 'all') return this.projects = projects;
      this.projects = this.projects.filter((data) => data.type === type);
    }
  }
}
</script>

<style scoped>

</style>