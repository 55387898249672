<template>
  <div class="header-top-area">
    <b-container>
      <b-row>
        <b-col md="6">
          <div class="header-top-left">
            <p>
              <i class="fa fa-envelope"></i>
              yourmail@gmail.com
            </p>
            <p>
              <i class="fa fa-phone"></i>
              Call Us: +88 00224292
            </p>
          </div>
        </b-col>
        <b-col md="3">
          <div class="header-top-right">
            <social-menu/>
          </div>
        </b-col>
       <b-col md="3">
        <div class="header-quote-btn">
          <a href="javascript:;">get a quote</a>
        </div>
      </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import SocialMenu from "./SocialMenu";
export default {
  name: "NavMenu",
  components: {SocialMenu}
}
</script>

<style scoped>

</style>