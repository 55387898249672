<template>
 <div>
   <!-- Twitter Area Start -->
   <section class="ekifa-twitter-area">
     <b-container>
       <b-row>
         <b-col>
           <div class="twitter-post">
             <div class="twitter-post-icon">
               <i class="fa fa-twitter"></i>
             </div>
             <div class="single-twite">
               <p>"Check out 'Ekifa - Business and Agency #HTML Template' on <a href="#">#templatemonster</a> by <a href="#">@Evrothemes</a> <a href="#">#templatemonster</a>"</p>
             </div>
           </div>
         </b-col>
       </b-row>
     </b-container>
   </section>
   <!-- Twitter Area End -->


   <!-- Footer Area Start -->
   <footer class="ekifa-footer-area">
     <div class="footer-top section_50">
       <b-container>
         <b-row>
           <b-col lg="3" md="6">
             <div class="single-footer-widget">
               <div class="footer-logo">
                 <b-link to="/">
                   <img :src="require('../assets/img/footer-logo.png')" alt="ekifa logo" />
                 </b-link>
               </div>
               <p>Aliquip exa consequat dui aut repahend vouptate elit cilum fugiat pariatur lorem dolor cit amet consecter adipisic elit sea vena eiusmod nulla</p>
               <!----- Social Menu--------->
               <social-menu main-class="footer-social"/>
             </div>
           </b-col>
           <b-col lg="3" md="6">
             <div class="single-footer-widget">
               <h3>recent post</h3>
               <div class="latest-post-footer clearfix">
                 <div class="latest-post-footer-left">
                   <img :src="require('../assets/img/footer-post-2.jpg')" alt="post" />
                 </div>
                 <div class="latest-post-footer-right">
                   <h4><a href="javascript:;">Website design trends for 2019</a></h4>
                   <p>January 14 - 2019</p>
                 </div>
               </div>
               <div class="latest-post-footer clearfix">
                 <div class="latest-post-footer-left">
                   <img :src="require('../assets/img/footer-post-3.jpg')" alt="post" />
                 </div>
                 <div class="latest-post-footer-right">
                   <h4><a href="javascript:;">UI experts and modern designs</a></h4>
                   <p>January 12 - 2019</p>
                 </div>
               </div>
             </div>
           </b-col>
           <b-col lg="3" md="6">
             <div class="single-footer-widget">
               <h3>main links</h3>
               <ul>
                 <li><a href="javascript:;"><i class="fa fa-angle-double-right "></i> About Ekifa</a></li>
                 <li><a href="javascript:;"><i class="fa fa-angle-double-right "></i> Delivery Information</a></li>
                 <li><a href="javascript:;"><i class="fa fa-angle-double-right "></i> Terms & Conditions</a></li>
                 <li><a href="javascript:;"><i class="fa fa-angle-double-right "></i> Customer support</a></li>
                 <li><a href="javascript:;"><i class="fa fa-angle-double-right "></i> Contact with an expert</a></li>
                 <li><a href="javascript:;"><i class="fa fa-angle-double-right "></i> community updates</a></li>
                 <li><a href="javascript:;"><i class="fa fa-angle-double-right "></i> upcoming updates</a></li>
               </ul>
             </div>
           </b-col>
           <b-col lg="3" md="6">
             <div class="single-footer-widget footer-contact">
               <h3>Contact Info</h3>
               <p><i class="fa fa-map-marker"></i> 4257 Street, SunnyVale, USA </p>
               <p><i class="fa fa-phone"></i> 012-3456-789</p>
               <p><i class="fa fa-envelope-o"></i> info@example.com</p>
               <p><i class="fa fa-fax"></i> 112-3456-7898</p>
             </div>
           </b-col>
         </b-row>
       </b-container>
     </div>
     <div class="footer-copyright">
       <b-container>
         <b-row>
           <b-col lg="6">
             <div class="copyright-left">
               <p>Copyright &copy; {{copyright}} | Designed With <i class="fa fa-heart"></i> by Deveable</p>
             </div>
           </b-col>
           <b-col lg="6">
             <div class="copyright-right">
               <ul>
                 <li><b-link to="/">home</b-link></li>
                 <li><b-link to="/about">about</b-link></li>
                 <li><b-link to="/all-services">services</b-link></li>
                 <li><b-link to="/all-projects">projects</b-link> </li>
                 <li><b-link to="/error-page">pages</b-link></li>
                 <li><b-link to="/blog">blog</b-link></li>
                 <li><b-link to="/contact">contact</b-link> </li>
               </ul>
             </div>
           </b-col>
         </b-row>
       </b-container>
     </div>
   </footer>
   <!-- Footer Area End -->
 </div>
</template>

<script>
import SocialMenu from "../components/menus/SocialMenu";
export default {
  name: "AppFooter",
  components: {SocialMenu},
  data() {
    return {
      copyright: new Date().getFullYear()
    }
  }
}
</script>

<style scoped>

</style>