/*=========================================================================================
File Name: Home.vue
Description: Home Page.
----------------------------------------------------------------------------------------
Item Name: Ekifa Vue || Business and Agency VueJs Template
Version: 1.0.0
Author: Deveable
Copyright 2021
==========================================================================================*/

<template>
  <div>
    <!-- Home Slider Component -->
    <home-slider/>
    <!-- Promo Area Component -->
    <promo-area/>
    <!-- Company Area Component -->
    <company-area/>
    <!-- Counter Area Component -->
    <counter-area/>
    <!-- Service Area Component -->
    <service-area/>
    <!-- Try Area Component -->
    <try-area/>
    <!-- Project Area Component -->
    <project-area/>
    <!-- Query Area Component -->
    <query-area/>
    <!-- Testimonial Area Component -->
    <testimonial-area/>
    <!-- Blog Area Component -->
    <home-blog-area/>
  </div>
</template>

<script>
import HomeSlider from "../components/HomeSlider";
import PromoArea from "../components/PromoArea";
import CompanyArea from "../components/CompanyArea";
import CounterArea from "../components/CounterArea";
import ServiceArea from "../components/ServiceArea";
import TryArea from "../components/TryArea";
import ProjectArea from "../components/ProjectArea";
import QueryArea from "../components/QueryArea";
import TestimonialArea from "../components/TestimonialArea";
import HomeBlogArea from "../components/HomeBlogArea";

export default {
  name: 'Home',
  components: {
    HomeBlogArea,
    TestimonialArea,
    QueryArea, ProjectArea, TryArea, ServiceArea, CounterArea, CompanyArea, PromoArea, HomeSlider}
}
</script>
