<template>
  <!-- Counter Area Start -->
  <section class="ekifa-counter-area section_50">
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-lg-6">
          <div class="counter-left">
            <h3>We complete every project with extra care as customer need</h3>
          </div>
        </div>
        <div class="col-md-12 col-lg-6">
          <div class="counter-right">
            <div class="single-count" v-for="(item, key) in counters" :key="key">
              <i :class="`fa fa-${item.icon}`"></i>
              <h3 class="counter">{{item.count}}</h3>
              <p>{{item.title}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Counter Area End -->
</template>

<script>
export default {
  name: "CounterArea",
  data() {
    return {
      counters: [
        {
          icon: 'globe',
          count: 450,
          title: 'global locations'
        },
        {
          icon: 'smile-o',
          count: 3400,
          title: 'happy customer'
        },
        {
          icon: 'users',
          count: 720,
          title: 'consultants'
        },
        {
          icon: 'thumbs-up',
          count: 1250,
          title: 'project done'
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>