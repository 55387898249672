<template>
  <ul :class="mainClass">
    <template v-for="(item, key) in links">
      <li :key="key"><a href="javascript:;"><i :class="item.class"></i></a></li>
    </template>
<!--
    <li><a href="javascript:;"><i class="fa fa-twitter"></i></a></li>
    <li><a href="javascript:;"><i class="fa fa-linkedin"></i></a></li>
    <li><a href="javascript:;"><i class="fa fa-youtube"></i></a></li>
    <li><a href="javascript:;"><i class="fa fa-skype"></i></a></li>-->
  </ul>
</template>

<script>
export default {
  name: "SocialMenu",
  data() {
    return {
      links: [
        {
          class: 'fa fa-facebook',
          path: ''
        },
        {
          class: 'fa fa-twitter',
          path: ''
        },
        {
          class: 'fa fa-linkedin',
          path: ''
        },
        {
          class: 'fa fa-youtube',
          path: ''
        },
        {
          class: 'fa fa-skype',
          path: ''
        },
      ]
    }
  },
  props: {
    mainClass: {
      type: String,
      default: function () {
        return '';
      }
    }
  }
}
</script>

<style scoped>

</style>